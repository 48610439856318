<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    en: {},
    pt: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: { Layout, VclList, Stat },
  data() {
    return {
      table: {
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },

      statData: null,
    };
  },
  methods: {
    getList() {
      this.table.body = null;

      this.table.loading = true;
      this.table.errored = false;
      this.table.empty = false;

      api
        .get("investments/contracts")
        .then((response) => {
          if (response.data.status == "success") {
            this.table.body = response.data.list;
            this.statData = [
              {
                title: "Contratos Ativos",
                value: this.$options.filters.currency(
                  response.data.total.approved
                ),
              },
              {
                title: "Contratos Pendentes",
                value: this.$options.filters.currency(
                  response.data.total.pending
                ),
              },
            ];
          } else if (response.data.status == "empty") {
            this.statData = [];
          }
        })
        .catch((error) => {
          this.table.errored = error;
        })
        .finally(() => {
          this.table.loading = false;
          if (this.table.body == "" || this.table.body == null) {
            this.table.empty = true;
          }
        });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">Meus Contratos</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="table.empty">{{ t("No records found.") }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th></th>
                    <th>Tipo</th>
                    <th>Prazo</th>
                    <th>Cotas</th>
                    <th>Valor</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td, index) in table.body" :key="index">
                    <td class="align-middle">
                      <img
                        v-if="td.cover"
                        class="type-cover rounded"
                        :src="td.cover"
                      />
                    </td>
                    <td class="align-middle"><strong>{{ td.type }}</strong></td>
                    <td class="align-middle">{{ td.time }}</td>
                    <td class="align-middle">{{ td.quotas }}</td>
                    <td class="align-middle">{{ td.value | currency }}</td>
                    <td class="align-middle">
                      <span
                        class="badge badge-danger text-uppercase font-size-12 p-2"
                        v-if="td.status == 'pending'"
                      >
                        <div class="d-flex align-items-center">
                          <i class="bx bx-timer font-size-20 align-bottom mr-1"></i>
                          <span>Pagameto pendente</span>
                        </div>
                      </span>
                      <span
                        class="badge badge-default text-uppercase font-size-12 p-2"
                        v-else-if="td.status == 'approved'"
                        >
                        <div class="d-flex align-items-center">
                          <i class="bx bx-timer font-size-20 align-bottom mr-1"></i>
                          <span>Pagameto aprovado</span>
                        </div>
                      </span>
                    </td>
                    <td class="align-middle text-right">
                      <router-link
                        v-if="td.status == 'pending'"
                        :to="'/investments/contracts/' + td.id"
                        class="btn btn-light btn-sm"
                      >
                        Ver
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.type-cover {
  object-fit: cover;
  width: 70px;
  height: 70px;
}
</style>
